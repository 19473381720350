import { Routes, Route, Link } from 'react-router-dom'
import Header from './components/header'
import Footer from './components/footer'
import Home from './pages/home'
import Services from './pages/services'
import Reviews from './pages/reviews'

import './stylesheets/App.css'

function App() {
  return (
    <>
      <Header />
      <div className='router-container'>
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/services' element={<Services />} />
            <Route path='/reviews' element={<Reviews />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;

import { Link } from 'react-router-dom'
import { MapPinned, Phone, Mail, Clock, Clock8 } from 'lucide-react'
import '../stylesheets/footer.css'

function Footer() {
    return (
        <div id='footer'>
            <nav>
                <Link to='/'>Home</Link>
                <Link to='services'>Services</Link>
                <Link to='reviews'>Reviews</Link>
            </nav>

            <section id='contact'>
                <IconText icon='phone'>(470) 502-6272</IconText>
                <IconText icon='email'>superiorsmarthome@gmail.com</IconText>
                <IconText icon='hours'>Weekdays: 9am - 7pm</IconText>
                <IconText icon='hours2'>Weekends: 9am - 7pm</IconText>
            </section>
        </div>
    )
}

function IconText({ icon, children }) {
    if (icon == 'phone')
        icon = <Phone />
    else if (icon == 'email')
        icon = <Mail />
    else if (icon == 'hours')
        icon = <Clock />
    else if (icon == 'hours2')
        icon = <Clock8 />
    
    return (
        <div className='iconText'>
            {icon}
            {children}
        </div>
    )
}

export default Footer;
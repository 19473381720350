import React, { useState, useEffect } from 'react'
import { Star } from 'lucide-react'
import Review from '../components/review'
import '../stylesheets/reviews.css'

import { db } from '../firebase-config'
import { collection, addDoc, getDocs } from 'firebase/firestore'

function Reviews() {
    const [name, setName] = useState("")
    const [rating, setRating] = useState(4)
    const [review, setReview] = useState("")
    const [hover, setHover] = useState(4)
    
    const [visible, setVisibility] = useState(false)
    
    const [reviews, setReviews] = useState([])

    // add review to database
    async function handleSubmit(e) {
        e.preventDefault();

        try {
            await addDoc(collection(db, 'reviews'), {
                name: name,
                rating: rating,
                review: review,
            })
        } catch (error) {
            console.error('Error adding document: ', error)
        }

        alert('Review submitted!')

        window.location.reload()
    }

    // get reviews from Firestore
    useEffect(() => {
        async function fetchReviews() {
            try {
                const querySnapshot = await getDocs(collection(db, 'reviews'))
                const reviewData = querySnapshot.docs.map(doc => doc.data())
                setReviews(reviewData)
            } catch (error) {
                console.error('Error fetching reviews: ', error)
            }
        }

        fetchReviews();
    }, [])

    function toggleVisibility() {
        setVisibility(!visible)
    }

    let totalRating = 0
    for (let i = 0; i < reviews.length; i++) {
        totalRating += reviews[i].rating
    }
    totalRating = totalRating / reviews.length

    return (
        <>
            <h1>Reviews</h1>

            <div className='rating-stars'>
                {[...Array(5)].map((star, index) => {
                    index += 1;
                    return (
                        <Star className={index <= Math.round(totalRating) ? "star checked" : "star unchecked"} />
                    )
                })}
                <span>({totalRating.toPrecision(3)})</span>
            </div>

            <section className='reviews'>
                {
                    reviews.map((review, index) => (
                        <Review
                            key={index}
                            name={review.name}
                            rating={review.rating}
                            review={review.review}
                        />
                   ))
                }
            </section>
            <div className='add-review-button'>
                <button onClick={toggleVisibility}>Add A Review</button>
            </div>
            
            {visible ? 
            
                <section className='add-review'>
                    <form onSubmit={handleSubmit}>
                        <label>
                            Name
                            <input
                                type='text'
                                value={name}
                                onChange={e => setName(e.target.value)}
                                required
                            />
                        </label>

                        <label>
                            Rating
                            <div className='rating-stars'>
                                {[...Array(5)].map((star, index) => {
                                    index += 1;
                                    return (
                                        <button
                                            type='button'
                                            key={index}
                                            onClick={() => setRating(index)}
                                            onMouseEnter={() => setHover(index)}
                                            onMouseLeave={() => setHover(rating)}
                                        >
                                            <Star className={index <= hover ? "star checked" : "star nchecked"} />
                                        </button>
                                    )
                                })}
                            </div>
                        </label>

                        <label>
                            Review (optional)
                            <textarea
                                cols='40'
                                rows='5'
                                value={review}
                                onChange={e => setReview(e.target.value)}
                            />
                        </label>

                        <input type='submit' style={{ backgroundColor: '#252f48', color: 'white' }} />
                    </form>
                </section>
            : <div></div>
            }
        </>
    )
}

export default Reviews;
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBLhm0FgVjuZUf6Xz8Ge993tdqOAdPHx5E",
  authDomain: "ssh-website-2f18d.firebaseapp.com",
  projectId: "ssh-website-2f18d",
  storageBucket: "ssh-website-2f18d.appspot.com",
  messagingSenderId: "393262640292",
  appId: "1:393262640292:web:dcab10bb0c815411c18a31"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

export { app, db }
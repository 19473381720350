import logo from '../resources/logo.png'
import Slideshow from '../components/slideshow'
import '../stylesheets/home.css'

// photo imports
import defaultImage from '../resources/defaultImage.jpg'
import defaultPfp from '../resources/defaultPfp.png'
import defaultVideo from '../resources/defaultVideo.mp4'

function Home() {
    const imageArray = [
        { url: defaultImage, alt: 'Default Image'},
        { url: defaultPfp, alt: 'Default Profile Picture'},
        { url: defaultVideo, alt: 'Default Video'}
    ]
    
    return (
        <div className='content-container'>
            <img src={logo} alt='Superior Smart Home Logo' id='logo'/>

            <p> Superior Smart Home is a company created by high schooler Vipul Bansal from Marietta, Georgia.
                We aim to provide a personalized and exemplary experience for each of our clients and offer FREE
                consultations to help customize the experience to each specific scenario.
            </p>
            <p>
                Let us help you modernize your home by installing smart home devices such as cameras,
                video doorbells, and virtual home assistants. See our services page for more details.
            </p>

            {/* <Slideshow images={imageArray} className='slideshow' /> */}
        </div>
    )
}

export default Home;
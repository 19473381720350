import ServiceNav from "../components/serviceNav";
import ServiceImg from '../components/serviceImg'

// image imports
import speakerImg from '../resources/speakerImage.jpg'
import doorbellImage from '../resources/doorbellImage.jpg'
import lockImage from '../resources/lockImage.jpg'
import garageImage from '../resources/garageImage.jpg'
import irrigationImage from '../resources/irrigationImage.jpeg'
import securityImage from '../resources/securityImage.jpg'
import voiceImage from '../resources/voiceImage.png'

function Services() {
    return (
        <div id='content-container'>
            <h1>Offered Services</h1>
            <p>Choose from one of the options below to view more information about each service we offer!</p>
            <ServiceNav />

            <ServiceImg
                id='Speakers'
                img={speakerImg}
                desc='Enjoy speakers from highly reputed brands, including Denon, Sonos, Dynaudio, and much more.
                      Tune each speaker for the room and your preferences.
                      Control audio from your phone, a wall-mounted touchpad, or with your voice.'
            />
            <ServiceImg
                id='Doorbells'
                img={doorbellImage}
                desc='View and talk to whoever is at your door with the click of a button.
                      Access the live feed on your phone, your computer, or a wall-mounted touchpad.
                      Create automations from doorbell conditions, including weather-detection and person-detection.'
            />
            <ServiceImg
                id='Locks'
                img={lockImage}
                desc='Remotely unlock and lock your doors.
                      Connect your locks to other automations and open up a world of possibilites.
                      Give guests virtual keys and limit their access to certain times.
                      Never worry about leaving a key under your doormat again!'
            />
            <ServiceImg
                id='Garages'
                img={garageImage}
                desc='Open and close your garage from your phone from anywhere in the world.
                      Easily create temporary guest access codes.
                      Enable secure in-garage deliveries to make sure your packages are always secure.'
            />
            <ServiceImg
                id='Irrigation'
                img={irrigationImage}
                desc='Control your irrigation system from your phone or computer.
                      Automatically adjust your watering schedule based on weather and soil conditions.
                      Fine tune your sprinkler system and allow your plants to grow like never before!'
            />
            <ServiceImg
                id='Security'
                img={securityImage}
                desc='Monitor your house while you are either home or away.
                      Choose from a combination of cameras, motion detectors, door/window contact sensors and more.
                      Connect to other smart devices to enhance security and allow for more customizability.'
            />
            <ServiceImg
                id='Voice Assistants'
                img={voiceImage}
                desc='Use Google Home, Amazon Alexa, Siri, or any combination of the three.
                      Control your entire smart home with your voice and one app.
                      Create custom automations to personalize your system.'
            />
        </div>
    )
}

export default Services;
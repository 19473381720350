import defaultPfp from '../resources/defaultPfp.png'
import { Star } from 'lucide-react'
import '../stylesheets/review.css'

function Review({ pfp, name, rating, review }) {
    if (!pfp)
        pfp = defaultPfp;

    // preconditions
    if (rating > 5)
        rating = 5;
    if (rating < 1)
        rating = 1;

    rating = Math.round(rating);

    return (
        <section className='review'>
            <div className='review-meta'>
                <img src={pfp} alt='profile picture' />
                <div>
                    <h3>{name}</h3>
                    <div className='star-rating'>
                        <div className='stars'>
                            { Array.from({ length: 5 }, () => (
                                <Star strokeWidth={1.5} />
                            ))}
                        </div>
                        <div className='stars rating'>
                            { Array.from({ length: rating}, () => (
                                <Star fill='yellow' strokeWidth={1} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className='review-p'>
                <p>{review}</p>
            </div>
        </section>
    )
}

export default Review;
import { Music, BellRing, Lock, Warehouse, CloudRainWind, Cctv, Mic } from 'lucide-react'
import '../stylesheets/serviceNav.css'

function ServiceNav() {
    return (
        <section className="serviceNav">
            <IconText icon='Speakers' />
            <IconText icon='Doorbells' />
            <IconText icon='Locks' />
            <IconText icon='Garages' />
            <IconText icon='Irrigation' />
            <IconText icon='Security' />
            <IconText icon='Voice Assistants' />
        </section>
    )
}

function IconText({ icon }) {
    let text = icon;

    if (icon === 'Speakers')
        icon = <Music />
    else if (icon === 'Doorbells')
        icon = <BellRing />
    else if (icon === 'Locks')
        icon = <Lock />
    else if (icon === 'Garages')
        icon = <Warehouse />
    else if (icon === 'Irrigation')
        icon = <CloudRainWind />
    else if (icon === 'Security')
        icon = <Cctv />
    else if (icon === 'Voice Assistants')
        icon = <Mic />
    
    return (
        <a className='servIcon' href={'#' + text} >
            {icon}
            {text}
        </a>
    )
}

export default ServiceNav;
import '../stylesheets/serviceImg.css'

function ServiceImg({ id, img, desc }) {
    return (
        <section
            className='serviceImage background-tint'
            id={id}
            style={{ backgroundImage: `url(${img})`}}
        >
            <h2>{id}</h2>
            {desc.split('.').map(sentence => <p>{sentence}</p>)}
        </section>
    )
}

export default ServiceImg;